<template>
  <div class="newsletter-section">
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <!-- <div id="progressBar">
    <div class="loader"></div>
      </div>-->
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-4 column">
              <div class="heading-profile">
                <h3>Newsletter</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="widget-title">
                    <div class="float-right">
                      <form class="search-form mr-form">
                        <input
                          type="text "
                          class="form-control"
                          v-model="searchText"
                          @input="viewNewsletterList(1)"
                          placeholder="Search Here..."
                        />
                      </form>
                      <!-- create router link -->
                      <!-- <router-link to="CreateUser" class="btn btn-success
                      btn-small">Create</router-link> -->
                    </div>
                    <div class="d-flex">
                      <div>
                        <h3>
                          List of Newsletter
                          <span class="tableTotal">(Total : {{ totalCount }})</span>
                        </h3>
                      </div>
                      <div class=" ml-3 ">
                        <b-button variant="success" @click="generateExcelForNewsletter(page)"
                          >Download</b-button
                        >
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table text-nowrap">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>

                          <th>Email</th>
                          <th>Date</th>
                          <!-- <th>Group</th> -->
                          <th>
                            <b-dropdown :text="selected ? selected.text : 'Groups'">
                              <b-dropdown-item
                                :disabled="option.disabled"
                                @click="selectedGroup(option)"
                                v-for="option in allGroups"
                                :key="option.value"
                              >
                                <div class="text">{{ option.text }}</div>
                              </b-dropdown-item>
                            </b-dropdown>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(group, index) in groups" v-bind:key="group._id">
                          <td>{{ index + 1 + (page - 1) * 10 }}</td>
                          <!-- <td>1</td> -->

                          <td>
                            <div>{{ group.email }}</div>
                          </td>
                          <td>
                            <div>{{ group.createdAt | moment("DD/MM/YYYY") }}</div>
                          </td>
                          <td>
                            <div>{{ group.groupName }}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="table-body-contents text-center m-3 font-size-md
                    font-weight-bold text-muted"
                    v-if="groups.length === 0"
                  >
                    No Data Found
                  </div>
                  <div class="text-center" v-if="groups.length >= 1">
                    <b-pagination
                      class="mb-0 float-right"
                      v-model="page"
                      align="center"
                      :total-rows="totalCount"
                      :per-page="formData.limit"
                      @input="viewNewsletterList(page)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerCustom from "@/components/Header.vue";
import apiservice from "@/service/apiservice";
import moment from "moment";

export default {
  components: {
    headerCustom
  },
  data() {
    return {
      id: "",
      logs: {
        idToDelete: ""
      },
      currentPage: 1,
      searchText: "",
      users: [],
      page: 1,
      formData: { limit: 10 },
      totalCount: 0,
      perPage: 10,
      errors: [],
      data: "",
      email: "",
      moment: moment,
      selected: null,
      groups: [],
      allGroups: [
        {
          text: "All",
          value: "All"
        },
        {
          text: "Hotelier",
          value: "Hotelier"
        },
        {
          text: "Wedding Planner",
          value: "Wedding Planner"
        },
        {
          text: "Bride / Groom",
          value: "Bride / Groom"
        },
        {
          text: "Friends/Family of Bride Groom",
          value: "Friends/Family of Bride Groom"
        },
        {
          text: "Travel Agent",
          value: "Travel Agent"
        }
      ]
    };
  },
  created() {
    this.viewNewsletterList(this.page);
  },
  methods: {
    viewNewsletterList(pageNo) {
      this.groups = [];
      this.formData.page = pageNo;
      this.formData.limit = this.perPage;
      this.formData.search = this.searchText;
      apiservice.viewNewsletterList(this.formData, data => {
        if (data.code === 200) {
          this.groups = data.data.result;
          this.totalCount = data.data.count;
        } else {
        }
      });
    },
    selectedGroup(option) {
      this.selected = option;
      if (option.text == "All") {
        this.formData.type = option.value;
        this.formData.groupName = "";
      } else {
        this.formData.groupName = option.value;
        this.formData.type = "";
      }
      this.viewNewsletterList(this.page);
    },
    generateExcelForNewsletter() {
      apiservice.generateExcelForNewsletter(this.formData, data => {
        apiservice.downloadExcel(data, "Newsletter_List");
      });
    }
  }
};
</script>

<style lang="scss">
.newsletter-section {
  .text {
    color: black;
  }
}
</style>
